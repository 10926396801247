export const APP_CONSTANT = {
  CONFIG: {
    ENV: '',
    CARB_APP_ID: '',
    APIGEE_URL: '',
    LOADED_URL: '',
    CAT_AUTH_TOKEN: '',
    MOMENT_LANGUAGE_CODE: '',
    APPLICATION_ID: '',
    SESSION_ID: '',
    CLIENT_ID: '',
    BRAND_CODE: 'F',
    FLOW_NAME: '',
    LANGUAGE: '',
    TRACE_ID: '',
    SPAN_ID: '',
    COUNTRY_CODE: '',
    LANGUAGE_CODE: '',
    PRELOAD_STATUS: false,
    DEVICE_TYPE: '',
    IS_OWNER: false,
    REGION: '',
    APPLICATION_ID_FOR_SAVE: '',
    EU_PRIVACY_POLICY_LINK: '',
    USER_GUID: '',
    ALEXA_APP_ID: '',
    SHOW_HEADER_FOOTER_EU: false,
    ONSET: '',
    APPLICATION_NAME: '',
  },
}

export const COOKIE_GROUP = Object.freeze({
  PERFORMANCE: 'group1',
  ANALYTICS: 'group2',
})

export const FEATURE_UI_NAME = {
  vehicleHealthToDealers: 'Vehicle Health To Dealers',
  californiaAirResourcesBoard: 'California Air Resources Board',
  amazonAlexa: 'Amazon Alexa',
  vehicleLocationData: 'Vehicle Location Data',
  vehicleData: 'Vehicle Data',
  preferredDealer: 'Preferred Dealer',
  gridServices: 'Grid Services',
  personalization: 'Personalization',
  googleMapsInFordPass: 'Google Maps in FordPass',
  txu: 'TXU',
}
export const APPLICATION_PATH = Object.freeze({
  LANDING: '/',
  MANAGE_DATA_WITH_FORD: '/manage-data-with-ford',
  MANAGE_DATA_WITH_THIRD_PARTIES: '/manage-data-with-third-parties',
  MOBILE_APP_COOKIES: '/mobile-app-cookies',
  UNLINK_FEATURE: '/manage-data-with-third-parties/unlink',
})

export const LANG_CONSTANT = {
  lbl_your_privacy_at_F: 'Your Privacy at Ford',
  lbl_your_privacy_at_L: 'Your Privacy at Lincoln',
  lbl_privacy_notices: 'Privacy Notices',
  lbl_privacy_notices_url_CAN_F: 'https://www.ford.ca/help/privacy/',
  lbl_privacy_notices_url_CAN_L: 'https://www.lincolncanada.com/help/privacy-policy/',
  lbl_privacy_notices_url_USA_F: 'https://www.ford.com/help/privacy/',
  lbl_privacy_notices_url_USA_L: 'https://www.lincoln.com/help/privacy-terms/#privacy',
  lbl_app_privacy_notice_F: 'FordPass Privacy Notice',
  lbl_app_privacy_notice_L: 'LincolnWay Privacy Notice',
  lbl_app_privacy_notice_url_USA_F:
    'https://www.fordpass.com/content/ford_com/fp_app/en_us/termsprivacy.html',
  lbl_app_privacy_notice_url_USA_L: 'https://www.lincoln.com/lincolnway/en_us/termsprivacy.html#two',
  lbl_app_privacy_notice_url_CAN_F:
    'https://www.fordpass.com/content/ford_com/fp_app/en_ca/termsprivacy.html',
  lbl_app_privacy_notice_url_CAN_L: 'https://www.lincoln.com/lincolnway/en_ca/termsprivacy.html',
  lbl_app_privacy_notice_url_F: 'https://www.fordpass.com/content/ford_com/fp_app/en_gb/terms.html',
  lbl_app_privacy_notice_url_L: 'https://www.lincoln.com/lincolnway/en_us/termsprivacy.html#two',
  lbl_your_privacy_choices: 'Your Privacy Choices',
  lbl_your_privacy_choices_url_F: 'https://www.ford.com/help/privacy/ccpa/',
  lbl_your_privacy_choices_url_L: 'https://www.lincoln.com/help/privacy/ccpa/',
  lbl_policy_info:
    'We’ve put policies in place to help safeguard your personal information and are committed to responsibly using information you share.',
  lbl_manage_data_F: 'Manage Data with Ford',
  lbl_manage_data_L: 'Manage Data with Lincoln',
  lbl_manage_data_third_parties: 'Manage Data with Third Parties',
  lbl_set_preferences: 'Set Preferences',
  lbl_policy_info_ford:
    'Ford uses information to improve functionality, recommend services, and to deliver personalized experiences so you get the most of your drive time.',
  lbl_policy_info_third_parties_USA:
    'Connected vehicle information shared with Third Parties assists in enabling the convenience of third party remote vehicle commands. ',
  lbl_policy_info_third_parties:
    'Connected vehicle information shared with Third Parties assists in maintaining the health of your vehicle, as well as enabling the convenience of third party remote vehicle commands. ',
  lbl_generic_support: 'support',
  lbl_support_link_L: 'https://owner.lincoln.com/support.html#/categories',
  lbl_support_link_F: 'https://help.ford.com',
  lbl_error_site_for_help_web_part2: 'site for help.',
  lbl_generic_error_web_part1: 'Something is not right. Please try again later or visit our',
  lbl_generic_error_L: 'Something is not right. Please try again later or contact a Concierge for help.',
  lbl_generic_error_F: 'Something is not right. Please try again later or contact a Guide for help.',
  lbl_refresh_upto_date_info: 'To view up to date information, please click the ‘refresh’ icon.',
  lbl_already_unlinked: 'You are unlinked. No further action is required.',
  lbl_alexa_generic_error: 'Something went wrong. Please wait a moment and try again.',
  lbl_vehicles_error_web_part1:
    'We are unable to retrieve permissions settings for all vehicles. Please try again later or visit our',
  lbl_vehicles_error_F:
    'We are unable to retrieve permissions settings for all vehicles. Please try again later or contact a Guide for help.',
  lbl_vehicles_error_L:
    'We are unable to retrieve permissions settings for all vehicles. Please try again later or contact a Concierge for help.',
  lbl_experience_layer_error_web_part1:
    'We are unable to retrieve permission settings. Please try again later or visit our',
  lbl_experience_layer_error_F:
    'We are unable to retrieve permission settings. Please try later or contact a Guide for help.',
  lbl_experience_layer_error_L:
    'We are unable to retrieve permission settings. Please try later or contact a Concierge for help.',
  lbl_remove_success: 'Your unlink request has been submitted.',
  lbl_remove_consent_failure_F:
    'We are unable to unlink your vehicle at this time. Please try later or contact a Guide for help.',
  lbl_remove_consent_failure_L:
    'We are unable to unlink your vehicle at this time. Please try later or contact a Concierge for help.',
  lbl_remove_consent_failure_web_part1:
    'We are unable to unlink your vehicle at this time. Please try again later or visit our',
  lbl_maintain_privacy_error_web_part1:
    'We are unable to complete the update at this time. Please try again later or visit our',
  lbl_maintain_privacy_error_F:
    'We are unable to complete the update at this time. Please contact a Guide for help or try again later.',
  lbl_maintain_privacy_error_L:
    'We are unable to complete the update at this time. Please contact a Concierge for help or try again later.',
  lbl_internet_connection: 'No internet connection.',
  lbl_go_back: 'Go back',
  lbl_select_vehicle: 'Select Vehicle',
  lbl_vehicle: 'Vehicle',
  lbl_last_updated_by: 'Last updated by',
  lbl_last_updated_at: 'Last updated at',
  lbl_at: 'at',
  lbl_on: 'on',
  'lbl_Google Maps in FordPass': 'Google Maps in FordPass',
  lbl_Tibber: 'Tibber',
  'lbl_Octopus Energy': 'Octopus Energy',
  'lbl_Polaris Ride Command': 'Polaris Ride Command',
  'lbl_Vehicle Health To Dealers': 'Vehicle Health To Dealers',
  'lbl_California Air Resources Board': 'California Air Resources Board',
  'lbl_Help Improve Road Safety': 'Help Improve Road Safety',
  'lbl_Electrify America': 'Electrify America',
  'lbl_Amazon Alexa': 'Amazon Alexa',
  lbl_FordConnect: 'FordConnect',
  'lbl_FordConnect Developer': 'FordConnect Developer',
  'lbl_Grid Services': 'Grid Services',
  'lbl_Mobile App Cookies': 'Mobile App Cookies',
  lbl_Personalization: 'Personalization',
  'lbl_2Hire Car Washing': '2Hire Car Washing',
  'lbl_2Hire Charging': '2Hire Charging',
  'lbl_2Hire Car Sharing': '2Hire Car Sharing',
  'lbl_2Hire Delivery To Vehicle': '2hire Delivery to Vehicle',
  'lbl_Vehicle Location Data': 'Vehicle Location Data',
  'lbl_Vehicle Data': 'Vehicle And Driving Data',
  'lbl_Preferred Dealer': 'Preferred Dealer Contact',
  'lbl_High Mobility Enhanced Insurance': 'High Mobility Enhanced Insurance',
  'lbl_High Mobility Insurance': 'High Mobility Insurance',
  'lbl_High Mobility Logbook': 'High Mobility Logbook',
  'lbl_High Mobility Vehicle Health': 'High Mobility Vehicle Health',
  'lbl_Caruso Enhanced Insurance': 'Caruso Enhanced Insurance',
  'lbl_Caruso Insurance': 'Caruso Insurance',
  'lbl_Caruso Logbook': 'Caruso Logbook',
  'lbl_Caruso Vehicle Health': 'Caruso Vehicle Health',
  lbl_learn_more: 'Learn more',
  lbl_unlink_third_party_accounts: 'Unlink Third Party Accounts',
  lbl_unlink_third_party_accounts_desc_F:
    'View and update the companies you have directed Ford to share your vehicle information with. ',
  lbl_unlink_third_party_accounts_desc_L:
    'View and update the companies you have directed Lincoln to share your vehicle information with. ',
  lbl_unlink_account: 'Unlink Account',
  lbl_learn_more_extended: 'about the information that will be shared with',
  lbl_date_of_consent: 'Date of Consent',
  lbl_mobile_app_cookies_para:
    'Necessary cookies are essential for core app functionality and security. With your consent, we use performance and analytics cookies to understand how you like to use the app, improve your app experience, and offer you more personalized services.',
  lbl_mac_group1: 'Performance',
  lbl_mac_cookies_group1: 'Performance Cookies',
  lbl_mac_group1_desc: 'To quickly fix issues and optimize your app',
  lbl_mac_group2: 'Analytics',
  lbl_mac_cookies_group2: 'Analytics Cookies',
  lbl_analytics_link: 'Learn more about our',
  lbl_analytics_link_text: 'Cookie Policy',
  lbl_mac_group2_desc: 'To help deliver improvements and offer more personalized services',
  lbl_turn_off: 'Turn Off',
  lbl_turn_on: 'Turn On',
  lbl_expand: 'Expand',
  lbl_collapse: 'Collapse',
  lbl_cancel: 'Cancel',
  lbl_turnOff_popup_text_MAC_group1_N:
    'Are you sure you would like to turn off Performance Cookies?  If you select Turn Off, you do not allow us to quickly fix issues and optimize your app.  You can turn Performance Cookies back on anytime by returning to “Manage Data with Ford” within the Privacy tile.',
  lbl_turnOff_popup_text_MAC_group2_N:
    'Are you sure you would like to turn off Analytics Cookies?  If you select Turn Off, you do not allow us to help deliver improvements and offer more personalized services.   You can turn Analytics Cookies back on anytime by returning to “Manage Data with Ford” within the Privacy tile.',
  lbl_turnOn_popup_text_MAC_group1_Y:
    'If you select Turn On Performance Cookies, you will allow us to quickly fix issues and optimize your app.',
  lbl_turnOn_popup_text_MAC_group2_Y:
    'If you select Turn On Analytics Cookies, you will allow us to help deliver improvements and offer more personalized services.',
  lbl_cookie_policy_link_url: 'https://www.fordpass.com/content/ford_com/fp_app/all_eu/cookie-policy/en.html',
  lbl_cookie_policy_link_turkey_url:
    'https://www.ford.com.tr/sartlar-ve-kisisel-verilerin-korunmasi/fordpass-cerez-aydinlatma-metni',
  lbl_unlink_vehicle: 'Unlink Vehicle',
  lbl_unlink: 'Unlink',
  lbl_unlink_vehicle_dialog_para1:
    'Tap "Unlink" to unlink your vehicle from the %variablefield% mobile app/website. If you unlink your vehicle from one %variablefield% mobile app or website, you will unlink your vehicle from all %variablefield% mobile apps and websites.',
  lbl_unlink_vehicle_dialog_para2:
    'To relink your vehicle, sign into your %variablefield% mobile app/website account.',
  lbl_unlink_vehicle_dialog_amazon_alexa:
    'When you unlink the vehicle, you will no longer be able to use Alexa Built-In features.:To use Amazon Alexa in the vehicle, you will have to complete the setup process again to link the vehicle to your Amazon Account.:When you tap Unlink, we will direct you to Amazon to complete your unlinking experience.:%appName% App version 4.32.0 or higher is required to start the unlinking experience here. If you don’t have a compatible version of the App, please update your App and return here, or login to your Amazon account and select the vehicle you want to unlink.',
  lbl_learn_more_section_title: 'What data gets shared?',
  lbl_vehicle_location_accordion_title: 'Vehicle Location',
  lbl_vehicle_data_accordion_title: 'Vehicle Data',
  lbl_vehicle_analytics_accordion_title: 'Vehicle Analytics',
  lbl_personal_information_accordion_title: 'Personal Information',
  lbl_driving_data_accordion_title: 'Driving Data',
  lbl_vehicle_location_data_accordion_title: 'Vehicle Location Data',
  lbl_contact_information_accordion_title: 'Contact Information',
  lbl_your_vehicle: 'Your Vehicle',
  lbl_Personalization_learn_more_para1: 'To help deliver experiences that are centered around you, we use:',
  lbl_turn_off_Personalization_popup:
    'Turning off personalization will limit our ability to make recommendations and deliver personalized experiences.:You can turn personalization back on at any time. Certain features, for example, Intelligent Charging, may require re-enrollment:Do you still want to turn off personalization?',
  lbl_turn_on_text:
    'I agree that Ford may analyze, learn from, and use information about my vehicle and how it is used, including driving data (such as speed and braking) and vehicle location, to make recommendations and deliver personalized experiences centered around me.',
  lbl_Personalization_vehicle_location_accordion_content:
    'Information about the location of your vehicle and if applicable charging locations used.',
  lbl_Personalization_driving_data_accordion_content:
    'Information about how the vehicle is operated and used (such as speed, use of accelerator, brakes, steering, seat belts, etc.) and the location and environment in which it is driven (such as the temperature outside the vehicle and road surface conditions).',
  lbl_Personalization_para1:
    'Analyzing and learning from your driving data, and the vehicle’s location, we can make recommendations and deliver driving experiences that are centered around you.',
  'lbl_turn_on_California Air Resources Board_popup':
    'If you select "Turn on" below, Ford will share the following information with CARB in connection with our Sustainable Charging Program:',
  'lbl_turn_on_California Air Resources Board_popup_pt2':
    'The amount of energy your vehicle charging consumes, along with the vehicle identification number (VIN) and the date and time of eligible charging events.',
  'lbl_turn_on_California Air Resources Board_popup_pt3':
    'The eligible location(s) where your vehicle receives charging.',
  'lbl_turn_on_California Air Resources Board_popup_pt4':
    "By participating in our Sustainable Charging Program and sharing the Vehicle Data and Vehicle Location data described above, you will help Ford's efforts to reduce greenhouse gas emissions.",
  'lbl_turn_off_California Air Resources Board_popup':
    'If you click "Turn off" below, Ford will stop sharing information about your electric vehicle charging with the California Air Resources Board (CARB) in connection with our EcoCharge Program.:If you change your mind, you can return to "Manage Data With Third Parties" at any time and turn on data sharing with CARB in connection with our EcoCharge Program.',
  'lbl_California Air Resources Board_para1_F':
    "Help Ford's efforts to reduce greenhouse gas emissions by participating in our Sustainable Charging Program. Through the sale of low-carbon fuel standard credits granted by the California Air Resources Board (CARB), we will promote transportation electrification through marketing, education, and incentives",
  'lbl_California Air Resources Board_para1_L':
    "Help Lincoln's efforts to reduce greenhouse gas emissions by participating in our Sustainable Charging Program. Through the sale of low-carbon fuel standard credits granted by the California Air Resources Board (CARB), we will promote transportation electrification through marketing, education, and incentives",
  'lbl_California Air Resources Board_learn_more_para1_F':
    "If you turn on data sharing, you will be agreeing to participate in Ford's Sustainable Charging Program, which helps our efforts to reduce greenhouse gas emissions.*Under the Sustainable Charging Program, we will generate or buy renewable energy certificates in amounts that offset the electricity you use when you charge your vehicle at eligible locations. We will share evidence of your electric vehicle charging with CARB to generate low-carbon fuel standard credits that can be sold.*We will use the revenue from those sales to promote transportation electrification through marketing, education, and incentives, which can encourage more electric vehicle ownership, which in turn supports our purchase of more renewable energy certificates to offset more electric vehicle charging. And so the cycle of supporting renewable energy continues!",
  'lbl_California Air Resources Board_learn_more_para1_L':
    "If you turn on data sharing, you will be agreeing to participate in Lincoln's Sustainable Charging Program, which helps our efforts to reduce greenhouse gas emissions.*Under the Sustainable Charging Program, we will generate or buy renewable energy certificates in amounts that offset the electricity you use when you charge your vehicle at eligible locations. We will share evidence of your electric vehicle charging with CARB to generate low-carbon fuel standard credits that can be sold.*We will use the revenue from those sales to promote transportation electrification through marketing, education, and incentives, which can encourage more electric vehicle ownership, which in turn supports our purchase of more renewable energy certificates to offset more electric vehicle charging. And so the cycle of supporting renewable energy continues!",
  'lbl_California Air Resources Board_learn_more_para2_F':
    'In connection with the Sustainable Charging Program, Ford will share the following information with CARB:',
  'lbl_California Air Resources Board_learn_more_para2_L':
    'In connection with the Sustainable Charging Program, Lincoln will share the following information with CARB:',
  'lbl_California Air Resources Board_vehicle_data_accordion_content':
    'The amount of energy your vehicle charging consumes, along with the vehicle identification number (VIN) and the date and time of eligible charging events.',
  'lbl_California Air Resources Board_vehicle_location_data_accordion_content':
    'The location(s) where your vehicle charges.',
  'lbl_Amazon Alexa_learn_more_para1':
    'When the vehicle is linked with your Amazon account, the following information is shared with Amazon.',
  'lbl_Amazon Alexa_learn_more_para2':
    'Not sharing this information does not affect other vehicle services, features, or settings.',
  'lbl_Amazon Alexa_learn_more_para3':
    'If Share Vehicle Data is turned off in the vehicle, you will be logged out of your Amazon account in the vehicle and you will need to complete the login process again.However, this will not unlink the vehicle from your Amazon account, and "Amazon Alexa" will still appear in your "Manage Data With Third Parties" menu.',
  'lbl_Amazon Alexa_learn_more_para4':
    'If Share Vehicle Location is turned off in the vehicle, certain Alexa functions will be limited.',
  'lbl_Amazon Alexa_vehicle_data_accordion_content':
    'Information about the vehicle, its components and parts, including status and performance, and diagnostics of vehicle systems (such as VIN, hardware model and part numbers, odometer, tire pressure, fuel and fluid levels, battery and lock status, trouble codes, warning indicators, alerts, and SYNC diagnostics), vehicle charging information (if applicable), and other information about how the vehicle is performing.',
  'lbl_Amazon Alexa_vehicle_location_accordion_content':
    'Precise location/GPS information about the vehicle, including current location, travel direction, speed, charging location used (if applicable), and information about the environment where the vehicle is operated (such as weather, road segment data, road surface conditions, traffic signs, and other surroundings).',
  'lbl_Vehicle Data_para1':
    'At Ford, we’re always working to improve our vehicles and services we provide; making your journeys better every day. We want to use your vehicle, driving and location data to improve quality/safety, optimise performance for environmental conditions, deliver new connected services, refine service recommendations and provide you with personalised offers and discounts.',
  'lbl_Vehicle Location Data_para1':
    'At Ford, we’re always working to improve our vehicles and services we provide; making your journeys better every day. We want to use your vehicle, driving and location data to improve quality/safety, optimise performance for environmental conditions, deliver new connected services, refine service recommendations and provide you with personalised offers and discounts.',
  'lbl_Vehicle Health To Dealers_para1_F':
    'Allows Ford to share connected vehicle data with Ford dealers to assist them in the service and repair of your vehicle.',
  'lbl_Vehicle Health To Dealers_para1_L':
    'Allows Lincoln to share connected vehicle data with Lincoln dealers to assist them in the service and repair of your vehicle.',
  'lbl_Vehicle Health To Dealers_learn_more_para1_F':
    'When enabled, Ford will share the following information:',
  'lbl_Vehicle Health To Dealers_learn_more_para1_L':
    'When enabled, Lincoln will share the following information:',
  'lbl_Vehicle Health To Dealers_vehicle_data_accordion_content_F':
    'Allows Ford to share connected vehicle data (such as odometer, diagnostic and vehicle health alerts) with dealers to help them diagnose vehicle issues.',
  'lbl_Vehicle Health To Dealers_vehicle_data_accordion_content_L':
    'Allows Lincoln to share connected vehicle data (such as odometer, diagnostic and vehicle health alerts) with dealers to help them diagnose vehicle issues.',
  'lbl_turn_off_Vehicle Health To Dealers_popup_F':
    'Turning off data sharing prevents dealers from receiving connected vehicle health data which may make your service visit less convenient.:If you change your mind later, you can come back to the Privacy Console at any time to turn on sharing data with Ford dealers.',
  'lbl_turn_off_Vehicle Health To Dealers_popup_L':
    'Turning off data sharing prevents dealers from receiving connected vehicle health data which may make your service visit less convenient.:If you change your mind later, you can come back to the Privacy Console at any time to turn on sharing data with Lincoln dealers.',
  'lbl_turn_on_Vehicle Health To Dealers_popup_pt1_F': 'Allows Ford to share the following information:',
  'lbl_turn_on_Vehicle Health To Dealers_popup_pt1_L': 'Allows Lincoln to share the following information:',
  'lbl_turn_on_Vehicle Health To Dealers_popup_pt2_F':
    'Allows Ford to share Connected Vehicle Data (such as odometer, diagnostic and vehicle health alerts) with dealers to help them in the service and repair of your vehicle.',
  'lbl_turn_on_Vehicle Health To Dealers_popup_pt2_L':
    'Allows Lincoln to share Connected Vehicle Data (such as odometer, diagnostic and vehicle health alerts) with dealers to help them in the service and repair of your vehicle.',
  'lbl_Preferred Dealer_para1_F':
    'Allows your preferred dealer to contact you when it may be time for service or maintenance based on the shared connected vehicle health data.',
  'lbl_turn_on_Preferred Dealer_para1':
    'Allows your preferred dealer to contact you when it may be time for service or maintenance based on the shared connected vehicle health data.:To proceed, please ensure you have a vehicle with an activated modem in your garage.',
  'lbl_turn_on_Preferred Dealer_para2_F': 'about the data Ford shares with your preferred dealer.',
  'lbl_turn_on_Preferred Dealer_para2_L': 'about the data Lincoln shares with your preferred dealer.',
  'lbl_turn_off_Preferred Dealer_popup':
    'Turning off data sharing may prevent the dealer from contacting you to schedule a service based on data sent from your connected vehicle such as odometer, diagnostic and maintenance alerts.:Do you still want to turn off data sharing for this purpose?:*You can always turn this feature back on if you choose.',
  'lbl_Preferred Dealer_learn_more_para1':
    'When enabled, Ford will share the following information with your preferred dealer so that they can contact you about your services and maintenance:',
  'lbl_Preferred Dealer_contact_information_accordion_content':
    'Such as your name and FordPass username/email address.',
  'lbl_Preferred Dealer_vehicle_data_accordion_content':
    'Connected vehicle data such as odometer, diagnostic and vehicle health alerts.',
  'lbl_Grid Services_para1':
    'Ford Electric Vehicle Grid Services work with utilities to help Ford drivers unlock new value and greener energy.',
  'lbl_Grid Services_para2_part1':
    'You have directed Ford to share data with Xcel Energy as part of a utility incentive program.',
  'lbl_Grid Services_para2_part2': 'about the data Ford shares with Xcel Energy.',
  'lbl_Grid Services_learn_more_para1': 'You’ve directed Ford to share this information with Xcel Energy:',
  'lbl_Grid Services_personal_information_accordion_content':
    'We share your name, home address and email with the utility company to confirm your enrollment in their incentive program.',
  'lbl_Grid Services_vehicle_data_accordion_content':
    'We share vehicle charging data, including one past year of historical data, such as date, amount of charge, charge start and end times to allow the utility company to assess the program and impact to the grid.',
  'lbl_Electrify America_learn_more_para1':
    'You’ve directed Ford to share this information with Electrify America:',
  'lbl_Electrify America_personal_information_accordion_content':
    'We share your VIN and a unique ID number we generate when you charge at Electrify America public charging stations.',
  'lbl_Electrify America_para1':
    'Electrify America is a company that owns and manages a network of EV high power charging stations.',
  'lbl_Electrify America_para2':
    'You have directed Ford to share data with Electrify America to enable the Charging Packs.',
  'lbl_Electrify America_para3':
    'To manage your charging packs log into your Ford account by launching the browser and navigating to ford.com/subscriptions.',
  'lbl_Amazon Alexa_para1':
    'With access to Alexa Built-In you can control your smart home devices, certain vehicle functions, and even purchase last-minute groceries right from the vehicle--all with your voice commands.',
  'lbl_Amazon Alexa_para2':
    'The shared information will be subject to Amazons privacy policy, which you can read on Amazons website to learn more about their privacy practices.',
  'lbl_Help Improve Road Safety_para1_F':
    'Whether you’re on a quick trip or a long road trip, sharing connected vehicle information can improve your journey. Share information from your vehicle with trusted third parties, who will use it, in combination with connected vehicle information shared by others, to help improve road safety, reduce traffic congestion, and contribute to a cleaner environment. Identifiable information is removed so it cannot be reasonably be linked back to you or your vehicle.',
  'lbl_Help Improve Road Safety_para2_F': 'about the information used to help improve road safety.',
  'lbl_Help Improve Road Safety_para1_L':
    'Whether you’re on a quick trip or a long road trip, sharing connected vehicle information can improve your journey. Share information from your vehicle with trusted third parties, who will use it, in combination with connected vehicle information shared by others, to help improve road safety, reduce traffic congestion, and contribute to a cleaner environment. Identifiable information is removed so it cannot be reasonably be linked back to you or your vehicle.',
  'lbl_Help Improve Road Safety_para2_L': 'about the information used to help improve road safety.',
  'lbl_Help Improve Road Safety_learn_more_para1':
    'Combined with intelligent roadside sensors and traffic reports, connected vehicle information can help make roads safer.',
  'lbl_Help Improve Road Safety_learn_more_para2':
    'Connected vehicle information helps build efficient road infrastructure and reduce traffic congestion.',
  'lbl_Help Improve Road Safety_learn_more_para3':
    'Information from electric and plug-in hybrid vehicles helps plan for and build electric grids and vehicle charging networks, contributing to a cleaner environment.',
  'lbl_Help Improve Road Safety_learn_more_para4':
    'Turning the setting off does not affect other services, features, or other settings.',
  'lbl_Help Improve Road Safety_learn_more_para5':
    'If a Factory Reset is completed and the vehicle is associated with a FordPass account, the setting will default to ON.',
  'lbl_Help Improve Road Safety_learn_more_para6':
    'The following information is de-identified and then shared with trusted third parties to help improve road safety, reduce traffic congestion, and contribute to a cleaner environment.',
  'lbl_Help Improve Road Safety_learn_more_para7':
    'If the in-vehicle privacy settings that correspond to the categories below are turned off, none of the information will be shared.',
  'lbl_Help Improve Road Safety_vehicle_data_accordion_content':
    'Information about the vehicle, its components and parts, including status and performance, and diagnostics of vehicle systems (such as VIN, hardware model and part numbers, odometer, tire pressure, fuel and fluid levels, battery and lock status, trouble codes, warning indicators, alerts, and SYNC diagnostics), vehicle charging information (if applicable), and other information about how the vehicle is performing.',
  'lbl_Help Improve Road Safety_vehicle_location_accordion_content':
    'Precise location/GPS information about the vehicle, including current location, travel direction, speed, charging location used (if applicable), and information about the environment where the vehicle is operated (such as weather, road segment data, road surface conditions, traffic signs, and other surroundings).',
  'lbl_Help Improve Road Safety_driving_data_accordion_content':
    'Information about how the vehicle is operated and used (such as speed, use of accelerator, brakes, steering, seat belts, etc.).',
  'lbl_turn_on_Vehicle Location Data_popup':
    'If you select Turn On, you allow us to use vehicle location data to improve quality/safety, optimise performance for environmental conditions, deliver new connected services, refine service recommendations and provide you with personalised offers and discounts.',
  'lbl_turn_off_Vehicle Location Data_popup':
    'If you select Turn Off, you do not allow us to use vehicle location data to improve quality/safety, optimise performance for environmental conditions, deliver new connected services, refine service recommendations and provide you with personalised offers and discounts.:You can turn Ford use of Vehicle Location Data back on anytime by returning to “Manage Data with Ford” within the Privacy tile.',
  'lbl_turn_on_Vehicle And Driving Data_popup':
    'If you select Turn On, you allow us to use vehicle and driving data to improve quality/safety, optimise performance for environmental conditions, deliver new connected services, refine service recommendations and provide you with personalised offers and discounts.',
  'lbl_turn_off_Vehicle And Driving Data_popup':
    'If you select Turn Off, you do not allow us to use Vehicle And Driving Data to improve quality/safety, optimise performance for environmental conditions, deliver new connected services, refine service recommendations and provide you with personalised offers and discounts.:You can turn Ford use of Vehicle And Driving Data back on anytime by returning to “Manage Data with Ford” within the Privacy tile.',
  'lbl_turn_off_Google Maps in FordPass':
    'Turning off data sharing disables Google Maps location-based mobile features such as public charging and vehicle location.',
  'lbl_turn_on_Google Maps in FordPass_para1':
    'Enjoy location-based mobile features, such as public charging or vehicle location.  By tapping “Turn On”, your location and device details will be shared with Google.:',
  'lbl_turn_on_Google Maps in FordPass_para2':
    '<privacyLink>Google’s Privacy Policy</privacyLink> describes their purposes for using the shared data, including providing Google Maps services, improving and promoting their products and services, and other purposes.    Precise location, device identifiers and technical information, maps performance and interaction data collected through Google cookies, and your search queries will be shared with Google.     This gives you access to mobile features that rely on maps, vehicle location, and points of interest, as well as search functionality.     You can stop sharing with Google here at any time, but the mobile features that rely on Google Maps services will be disabled.',
  'lbl_turn_on_Google Maps in FordPass_para2_CAN':
    'If you choose to use Google Maps in the App, Google may use cookies on your FordPass App.<privacyLink>Google’s Privacy Policy</privacyLink> describes their purposes for using the shared data, including providing Google Maps services, improving and promoting their products and services, and other purposes.    Precise location, device identifiers and technical information, maps performance and interaction data collected through Google cookies, and your search queries will be shared with Google. This gives you access to mobile features that rely on maps, vehicle location, and points of interest, as well as search functionality.    You can stop sharing with Google here at any time, but the mobile features that rely on Google Maps services will be disabled.',
  'lbl_Google Maps in FordPass_para1':
    'Enjoy Google Maps location-based mobile features, such as public charging or vehicle location.',
  'lbl_Google Maps in FordPass_learn_more_para1':
    'When enabled, the following data will be shared with Google for their purposes described in <privacyLink>Google’s Privacy Policy</privacyLink> including providing Google Maps services, improving and promoting their products and services, and other purposes.',
  lbl_precise_location_accordion_title: 'Precise Location',
  lbl_identifiers_accordion_title: 'Identifiers',
  lbl_technical_device_information_accordion_title: 'Technical Device Information',
  lbl_map_performance_integration_data_accordion_title: 'Maps Performance and Interaction Data',
  lbl_your_search_queries_accordion_title: 'Your Search Queries',
  'lbl_Google Maps in FordPass_precise_location_accordion_content':
    'depending on the FordPass feature being used, this could be the precise location of a vehicle, your mobile device, a charging station, or another place or point of interest you search for;',
  'lbl_Google Maps in FordPass_identifiers_accordion_content':
    'such as device IP address, a unique Google Maps Software Development Kit (SDK) identifier, a generic FordPass App identifier, etc.',
  'lbl_Google Maps in FordPass_technical_device_information_accordion_content':
    'such as device type, operating system version number, SDK version number, screen size, color theme, etc.',
  'lbl_Google Maps in FordPass_map_performance_integration_data_accordion_content':
    'such as panning, zooming, and Maps crash metrics, etc.',
  'lbl_Google Maps in FordPass_your_search_queries_accordion_content': 'points of interest you search for',
  lbl_google_privacy_policy_link: 'https://policies.google.com/privacy',
  lbl_privacy_notices_url_MEX_F: 'https://www.ford.mx/legal/privacidad/#clientes',
  lbl_privacy_notices_url_MEX_L: 'https://www.lincoln.mx/legal/aviso-privacidad-clientes-potenciales/',
  lbl_app_privacy_notice_url_MEX_F: 'https://www.ford.mx/legal/fordpass/privacidad/',
  lbl_app_privacy_notice_url_MEX_L: 'https://www.lincoln.mx/legal/aviso-privacidad-way-app/',
  lbl_TXU: 'TXU',
  lbl_TXU_para1_F: 'Share data with TXU to be eligible for the benefits of its vehicle charging program.',
  lbl_TXU_learn_more_para1:
    'When enabled, Ford uses and shares your charging session details with TXU for its vehicle charging program as described below. Shared data is subject to the TXU privacy policy.',
  lbl_turn_off_TXU_para1:
    'If you select “Turn Off”, Ford will stop sharing data with TXU, and you will be ineligible to participate in its program and will not receive any applicable benefits for charging.#',
  lbl_turn_off_TXU_para2:
    'Selecting "Turn off" does not unenroll you from the TXU  program. To unenroll, log in to your TXU account.#',
  lbl_turn_off_TXU_para3:
    'To begin sharing data with TXU and be eligible to participate in the program, you must turn the setting back on.',
  lbl_turn_on_TXU_para1:
    'If you select “Turn On”, you direct Ford to use and share your charging details with TXU for its vehicle charging program as described below.#',
  lbl_turn_on_TXU_para2:
    '<strong>Vehicle Data</strong>: Share data about your vehicle and home charging sessions (for example VIN, battery charging level, plug-in times, energy usage, state of charge, charging power level, etc.).#',
  lbl_turn_on_TXU_para3:
    '<strong>Vehicle Location</strong>: Use vehicle location when plugged in to identify home vs. non-home charging (not shared with [TXU]).#',
  lbl_turn_on_TXU_para4:
    '<strong>Aggregated Charging Data</strong>: Share data aggregated across home and non-home charging sessions (for example total energy used, percentage of charging at home or away, etc.).#',
  lbl_turn_on_TXU_para5: 'Shared data is subject to the TXU privacy policy.#',
  lbl_turn_on_TXU_para6:
    'Please note that TXU is a third-party provider and not affiliated with Ford Motor Company. Ford is not responsible for TXU’s services or how it uses data.',
  lbl_aggregated_charging_data_accordion_title: 'Aggregated Charging Data',
  lbl_TXU_vehicle_data_accordion_content:
    'Share data about your vehicle and home charging sessions (for example VIN, battery charging level, plug-in times, energy usage, state of charge, charging power level, etc.).',
  lbl_TXU_vehicle_location_accordion_content:
    'Use vehicle location when plugged in to identify home vs. non-home charging (not shared with [TXU]).',
  lbl_TXU_aggregated_charging_data_accordion_content:
    'Share data aggregated across home and non-home charging sessions (for example total energy used, percentage of charging at home or away, etc.)',
}
